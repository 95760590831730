@charset "UTF-8";
/* Slider */
.slick-loading .slick-list
{
    background: #fff url("/image/loading.gif") center center no-repeat;
}
@font-face {
			font-family: "SegoeUIRegular";
			src: url("/fonts/SegoeUIRegular/SegoeUIRegular.eot");
			src: url("/fonts/SegoeUIRegular/SegoeUIRegular.eot?#iefix")format("embedded-opentype"),
			url("/fonts/SegoeUIRegular/SegoeUIRegular.woff") format("woff"),
			url("/fonts/SegoeUIRegular/SegoeUIRegular.ttf") format("truetype");
			font-style: normal;
			font-weight: normal;
	}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 45px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 32px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: 15px;
    z-index: 1;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    color: rgba(246, 140, 6, 0.3);
    content: '\2190';
}
[dir='rtl'] .slick-prev:before
{
    content: '\2190';
}

.slick-next
{
    right: 15px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    color: rgba(246, 140, 6, 0.3);
    content: '\2192';
}
[dir='rtl'] .slick-next:before
{
    content: '\2192';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: 15px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li
{
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    border: 0;
    outline: none;
    background: #f68c06;
    border-radius: 50%;
    -webkit-box-shadow: inset inset 3px 3px 6px -2px rgba(0,0,0,0.80);
    -moz-box-shadow: inset 3px 3px 6px -2px rgba(0,0,0,0.80);
    box-shadow: inset 3px 3px 6px -2px rgba(0,0,0,0.80);
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 0px;
    line-height: 20px;

    width: 20px;
    height: 20px;

    content:'\2022';
    text-align: center;

    color: #f68c06;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button
{
    background: #515151;
}
/* custom selectors */
.slider-wrapper{
  margin: auto;
  position: relative;
}
.slider-item{
  width: 100%;
  height: auto;
}
.slick-slide{
  height: auto;
}
.slider-capacity{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 260px;
  height: 90%;
  overflow: hidden;
  padding: 10px 15px;
  margin: 20px 60px;
  background-color: rgba(255,255,255,0.8);
}
.slider-capacity>p{
  font-size: 18px;
  font-family: 'SegoeUIRegular';
  line-height: 24px;
  color: #355c89;
}
.slider-capacity>img{
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}
