/*//////////////////NEW VACANCY//////////////*/

@font-face {
    -ms-font-family: "SegoeUI";
    -ms-src: url(../fonts/SegoeUIRegular/SegoeUIRegular.ttf);
    font-family: "SegoeUI";
    src: url(../fonts/SegoeUIRegular/SegoeUIRegular.ttf);
    -ms-font-family: "ACLine";
    -ms-src: url(../fonts/ACLine.ttf);
    font-family: "ACLine";
    src: url(../fonts/ACLine.ttf);
}

.l-form-vacancy {
    display: flex;
    flex-direction: column;
}

.header-text-vacancy {
    color: #F68C06;
    /*margin-left: 15px;*/
    margin-bottom: 0;
    font-family: "ACLine";
    -ms-font-family: "ACLine";
    font-size: 36px;
    font-weight: bold;
}

.label-text-vacancy {
    line-height: 44px;
    display: inline-block;
    min-width: 167px;
    font-family: "Segoe UI", sans-serif;
    -ms-font-family: "Segoe UI", sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #666666;
}

.inputPlace {
    width: 100%;
    height: 44px;
    border-radius: 5px;
    border: 1px solid #D6D6D6;
}

/*///////////////NEW COMPANY////////////*/

.left {
    padding-left: 17px;
}

.red-star {
    color: red;
}

.err-info {
    color: white;
}

.err-info .err-message:not(:hover) {
    position: relative;
    color: white;
    animation-name: err;
    animation-duration: 5s;
}

@keyframes err {
    0% {
        color: white;
    }
    75% {
        color: red;
        left: 0px;
        top: 0px;
    }
    100% {
        color: white;
        left: 200px;
        top: 0px;
    }
}

.l-header-text-company {
    margin-bottom: 15px;
}

.header-text-company {
    color: #F68C06;
    margin-bottom: 0;
    font-family: "ACLine";
    -ms-font-family: "ACLine";
    font-size: 36px;
    font-weight: bold;
}

.form-company-row {
    display: flex;
    align-items: center;
}

.label-text-company {
    line-height: 45px;
    display: inline-block;
    text-align: right;
    min-width: 167px;
    font-family: "Segoe UI", sans-serif;
    -ms-font-family: "Segoe UI", sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #666666;
}

.after-form-item {
    margin-top: 15px;
    text-align: left;
    line-height: 30px;
}

.inputPlace2 {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    border: 1px solid #D6D6D6;
}

.dangerIco {
    width: 20px;
}

.upload-image {
    padding-bottom: 15px;
}

/*//////////////////NEW RESUME////////////*/

span.red-star {
    color: red;
    width: 5px;
}

.header-resume {
    margin-bottom: 20px;
}

.header-text-resume {
    color: #F68C06;
    margin-bottom: 0;
    font-family: "ACLine";
    -ms-font-family: "ACLine";
    font-size: 36px;
    font-weight: bold;
}

.label-text-resume {
    display: inline-block;
    font-family: "Segoe UI", sans-serif;
    -ms-font-family: "Segoe UI", sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #666666;
}

.select-width {
    width: 100%;
}

.resume-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.resume-form {
    display: flex;
    justify-content: space-between;
}

.resume-form-input {
    flex-basis: 100%;
}

.resume-form-f {
    margin-left: 22.3%;
    margin-bottom: 2%;
}

.change_description {
    display: none;
}

.change_description p {
    margin-left: 20px;
}

input.btn.btn-primary {
    color: white;
    background-color: #F68C06;
    border-color: #F68C06;
}

input.btn.btn-primary:hover {
    color: white;
    background-color: #f26522;
    border-color: #f26522;
}

input.btn.btn-primary:focus {
    color: white;
    background-color: #f26522;
    border-color: #f26522;
}