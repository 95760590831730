.header-tabs{
    margin-bottom:-0px;
    padding-left: 0px;
}

.header-tabs>ul>li {
    padding-bottom:0;
    font-family: SegoeUIRegular;
    font-size: 18px;
}

.header-tabs img {
    margin-right:10px;
}

.header-tabs>ul>li.active>a, .header-tabs>ul>li.active>a:focus, .header-tabs>ul>li.active>a:hover {
    color:#999999;
}

.header-tabs>ul>li>a, .header-tabs>ul>li>a:focus, .header-tabs>ul>li>a:hover {
    color:#666666;
}

.header-tabs>ul>li>a {
    border: 1px solid #dddddd;
    margin-right:-1px;
    height:50px;
}
.cabinet-buttons{
    margin-right: 15px;
    margin-left: 15px;
}
.contentAjax{
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 15px;
}