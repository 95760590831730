

.response-call btn-default btn{
	color: #bb8a35;
}

.file-call btn-default btn > a {
	color: #bb8a35;
}


#navregenterbutn {
	margin: -4px;
}

.header-nav-tabs{
	margin-bottom:-0px;
    padding-left: 0px;
}

.header-nav-tabs>ul>li {
	padding-bottom:0;
	font-family: SegoeUIRegular;
	font-size: 18px;
}

.header-nav-tabs img {
	margin-right:10px;
}

.header-nav-tabs>ul>li.active>a, .header-nav-tabs>ul>li.active>a:focus, .header-nav-tabs>ul>li.active>a:hover {
	color:#999999;
}

.header-nav-tabs>ul>li>a, .header-nav-tabs>ul>li>a:focus, .header-nav-tabs>ul>li>a:hover {
	color:#666666;
}

.header-nav-tabs>ul>li>a {
	border: 1px solid #dddddd;
	margin-right:-1px;
	height:50px;
}

.padding-top-div{
	padding-top:20px;
	margin:0;
}

.add-list-group {
	font-family: "SegoeUIRegular";
	float: right;
	padding-top:10px;
}

.list-inline {
	padding-left: 0px;
	margin-left: 0px;
	margin-bottom:0;
}

.list-unstyled_plus {
	margin-right: 0px;
}

.add {
	font-size: 18px;
	color: #999999;
	font-family: "SegoeUIRegular";
}

.glyphicon-plus {
	font-size: 18px;
	color: #999999;
	font-family: "SegoeUIRegular";
}

.list-unstyled_vacansy a {
	color:  #F68C06;
	font-family: "SegoeUIRegular";
	text-decoration: underline;
	font-size: 18px;
}

.list-unstyled_company a {
	color:  #F68C06;
	font-family: "SegoeUIRegular";
	text-decoration: underline;
	font-size: 18px;
}
.list-unstyled_resume a {
	color:  #F68C06;
	font-family: "SegoeUIRegular";
	text-decoration: underline;
	font-size: 18px;
}

.bottom-line4row-tab {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-right:15px;
}

.dropdown-menu{
	width: 100%;
	position: absolute;
}

#dropdownMenu1 {
    border: none;
    margin-right: 15px;
}

.btn-default:hover {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
}

#cross{
    color: red;
    font-size: 36px;
    line-height: 36px;
}

.btn-default {
    border-color: #ccc;
}

.plus-dropdn span {
    color: #cccccc;
    font-size: 36px;
    line-height: 36px;
}

.plus-dropdn{
    color: #cccccc;
    font-size: 36px;
    line-height: 36px;
}

.plus-dropdn-h {
    color: #f68c06;
    padding: 0 10px;
    font-weight: bold;
}

.nav-tabs {
    border-bottom:none;
}

.add-list-group-nav-tab .list-inline{
    margin-top: 15px;
}

.row.bottom-line4row-tab.few col-md-5.hidden-xs.hidden-sm.add-list-group-nav-tab{
    padding-right: 0px;
    padding-left: 0px;
    height: 50px;
}

.row.bottom-line4row-tab.few{
    margin-top: 10px;
}
@media(min-width: 535px) and (max-width: 575px) {
	.bottom-line4row-tab .header-nav-tabs li{
		font-size: 16px;
	}
}
@media(min-width: 500px) and (max-width: 534px) {
	.bottom-line4row-tab .header-nav-tabs li{
		font-size: 14px;
	}
	.bottom-line4row-tab .header-nav-tabs li img{
		margin: 0;
		width: 19px;
	}
}
@media(min-width: 466px) and (max-width: 499px) {
	.bottom-line4row-tab .header-nav-tabs li{
		font-size: 14px;
	}
	.bottom-line4row-tab .header-nav-tabs li a{
		padding: 10px;
	}
	.bottom-line4row-tab .header-nav-tabs li img{
		margin: 0;
		width: 19px;
	}
}
@media(min-width: 400px) and (max-width: 465px) {
	.bottom-line4row-tab .header-nav-tabs li{
		font-size: 14px;
	}
	.bottom-line4row-tab .header-nav-tabs li a{
		padding: 5px;
	}
	.bottom-line4row-tab .header-nav-tabs li img{
		margin: 0;
	}
}
@media(min-width: 300px) and (max-width: 399px) {
	.bottom-line4row-tab .header-nav-tabs li{
		font-size: 18px;
		width: 265px;
	}
	/*.bottom-line4row-tab .header-nav-tabs li a{*/
		/*padding: 5px;*/
	/*}*/
	.bottom-line4row-tab .header-nav-tabs li img{
		width: 21px;
	}
}
