
.paginatorBlock{
    margin: 0;
}

.pagination{
    height: 30px;
    display: inline-block;
    float: right;
    margin: 0;
}

ul.pagination li.disabled.hidden-xs span{
    text-align: center;
    letter-spacing: 1px;
    border: none;
    width: 20px;
    padding-left: 0;
    padding-right: 0;
}

ul.pagination a{
    border: 1px solid #CBCBCB;
    margin: 0 3px 0 3px;
    height: 30px;
    width: 30px;
    border-radius: 0;
    text-align: center;
    padding: 0;
    line-height: 30px;
}

ul.pagination li.active{
    height: 30px;
    width: 30px;
    text-align: center;
    background-color: #CCCCCC;
}

ul.pagination li.active span{
    background-color: #CCCCCC;
    border: none;
    padding-left: 0;
    padding-right: 0;
    margin-left: 3px;
    margin-right: 3px;
    width: 30px;
    height: 30px;
}

ul.pagination li.active span:hover{
    background-color: orange;
}

ul.pagination>li.disabled span, ul.pagination li.next span {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
    height: 30px;
    width: 30px;
}

ul.pagination>li>a:hover{
    background-color: orange;
    cursor: pointer;
    color: white;
    border: 1px solid #ff5200;
}

ul.pagination a{
    color: black;
}

li.disabled span, li.next>a, li.prev>a{
    font-weight: 700;
    color: #777777;
    margin-left: 3px;
    margin-right: 3px;
}

ul.pagination>.hide-md{
    display: none;
}

@media(min-width: 0px) and (max-width: 770px) {
    ul.pagination>.hide-md{
        display: inline;
        font-size: x-small;
    }
}