.nav-tabs {
    border-bottom: 0px solid;
}

.text-left-modal .list-modal-left-register,
.text-left-modal .list-modal-left,
.text-left-modal .minilogo {
    text-transform: uppercase;
}

#rightModal input[type=checkbox] {
    height: auto;
}

nav.navbar.navbar-default {
    margin-top: 12px;
    margin-bottom: 12px;
}

.edit-user-name {
    width: 100%;
    text-align: center;
    line-height: 32px;
    color: #ff930b;
}

.edit-user-name:hover {
    color: #c77308;
}

.col-xs-offset-6 {
    color: orange;
}

#navregenterbutn {
    margin: 17px;
    float: right;
}

img#entry {
    margin-right: 5px;
    padding: 0;
}

img#registry {
    padding: 0;
}

@media (min-width: 1300px) {
    .modal-regestry{
        padding: 0;
    }

    .modal-enter {
        padding: 0;
    }

    .center-block {
        padding-left: 0;
        padding-right: 27px;
    }
    /*Right modal*/
    .navtab-registraion {
        padding: 0px;
        margin-top: 15px;
        float: left;
    }

    .navtab-registraion button {
        background-color: white;
        border-color: white;
    }

    .navtab-registraion button:hover {
        background-color: white;
        border-color: white;
    }

    .navtab-registraion button.btn.btn-default.modal-enter:focus {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    .navtab-registraion button.btn.btn-default.modal-regestry:focus {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    #rightModal .modal-content {
        width: 290px;
        margin-top: 16px;
        margin-left: 881px;
        border-radius: 15px;
        border-color: #f68c06;
        background-color: #C4C4C4;
    }

    #rightModal .modal-content #modalTab {
        background-color: #C4C4C4;
        border-radius: 15px 15px 0 0;
    }

    #rightModal .modal-content ul#modalTab .btn-modal-enter {
        width: 140px;
        margin-right: 0px;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        color: #f68c06;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-enter {
        border-top-color: #f68c06;
        border-left-color: #f68c06;
        border-right-color: #f68c06;
        color: #f68c06;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-enter-opacity {
        background-color: #C4C4C4;
        opacity: 0.7;
    }

    #rightModal .modal-content ul#modalTab .btn-modal-reg {
        width: 148px;
        margin-right: 0px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: #f68c06;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-reg {
        border-top-color: #f68c06;
        border-left-color: #f68c06;
        border-right-color: #f68c06;
        color: #f68c06;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-reg-opacity {
        background-color: #C4C4C4;
        opacity: 0.7;
    }

    #rightModal .modal-content ul#modalTab .leftBtn-modal-tab {
        margin-left: 15px;
    }

    #rightModal .modal-content .form-group {
        margin-bottom: 5px;
    }

    #rightModal .modal-content .form-group .control-label {
        display: none;
    }

    #rightModal .modal-content .form-group input.form-control {
        border: 1px solid #333;
        width: 258px;
    }

    #rightModal .modal-content .form-group div.col-md-6.col-md-offset-4 {
        margin-left: 0px;
        width: 288px;
        height: 20px;
    }

    #rightModal .modal-content .form-group .checkbox {
        padding: 0;
    }

    #rightModal .modal-content .form-group button.btn.btn-primary.enter-button {
        width: 258px;
        height: 34px;
    }

    #rightModal .modal-content .form-group .member-btn {
        display: none;
    }

    #rightModal .modal-content .form-group a {
        color: #333;
        text-decoration: underline;
        font-size: 12px;
        height: 25px;
    }

    #rightModal .modal-content .tab-content #panel1 p {
        font-size: 12px;
        color: #9d9d9d;
        margin: 0px;
    }

    #rightModal .modal-content .tab-content #panel1 .modal-social-icon {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    #rightModal .modal-content .tab-content #panel1 .modal-social-icon .fa-soc {
        margin: 0;
    }

    #rightModal .modal-content .tab-content #panel2 .form-group .register-button {
        width: 258px;
        height: 34px;
    }

    #rightModal .modal-content .tab-content #panel2 .form-group .checkbox {
        font-size: 12px;
    }

    #rightModal .modal-content .tab-content #panel2 p {
        font-size: 12px;
        margin-top: 30px;
        margin-bottom: 0px;
        color: #9d9d9d;
    }

    #rightModal .modal-content .tab-content #panel2 .modal-social-icon {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    #rightModal .modal-content .tab-content #panel2 .modal-social-icon .fa-soc {
        margin-left: 0px;
    }

    #rightModal .modal-content .tab-content {
        border: 1px solid #f68c06;
        border-radius: 0 0 15px 15px;
        background-color: white;
    }

    /*End right modal*/
    nav.navbar.navbar-default {
        border-top-width: 0px;
        border-right-width: 0px;
        border-left-width: 0px;
        background-color: white;
        padding: 0px;
    }

    .navtab-exit p {
        font-size: 17px;
        margin: 9px;
    }

    .navtab-exit + .navtab-exit {
        width: 80px;
    }

    .navtab-exit a {
        text-decoration: none;
        float: right;
    }

    .navtab-exit {
        padding: 0;
    }

    .navtab-exit a {
        text-decoration: none;
    }

    .img-user {
        width: 45px;
        height: 45px;
        float: left;
        border: 1px solid #f68c06;
    }

    .img-user img {
        padding: 0px;
    }

    .img-user-name {
        display: table-cell;
        vertical-align: middle;
        padding-left: 3px;
        padding-right: 1px;
    }

    .navtab-exit .modal-user-button {
bottom:25px;
        /*margin-right: 25px; 
        margin-top: -25px;*/
        padding: 3px;
        border: 0;
        color: #f68c06;
    }

    .navtab-exit .modal-exit-button {
        padding: 3px;
        border: 0;
        color: #f68c06;
    }

    .navtab-exit button:hover {
        background-color: white;
        color: #f68c06;
        text-decoration: underline;
    }

    .navtab-exit .modal-exit-button:focus {
        color: #f68c06;
        background-color: white;
        outline-color: white;
    }

    .navtab-exit .modal-user-button:focus {
        color: #f68c06;
        background-color: white;
        outline-color: white;
    }

    nav.navbar.navbar-default {
    }

    .navtab-registraion ul.nav.nav-tabs {
        float: right;
        margin-top: 16px;
    }

    nav.navbar.navbar-default .center-block img {
        padding-left: 0px;
        padding-right: 0px;
    }

    /*Left modal*/
    button.btn.btn-default.only-bars {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    button.btn.btn-default.only-bars:hover {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    .text-left-modal a {
        color: #333;
        text-decoration: none;
    }

    .text-left-modal a:hover {
        color: #f68c06;
        text-decoration: none;
    }

    .text-left-modal a:focus {
        color: #f68c06;
        text-decoration: none;
    }

    #leftModal .modal-content {
        margin-left: 7.67%;
    }

    #leftModal .modal-content button.btn.btn-default.col-xs-3.list-in-bars {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    #leftModal .modal-content button.btn.btn-default.col-xs-3.list-in-bars:hover {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    #leftModal .modal-content .bars-left {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 10px;
    }

    #leftModal .modal-content .bars-left span {
        color: #f68c06;
    }

    #leftModal .list-modal-left {
        height: 25px;
        padding-left: 0px;
        padding-right: 0px;
    }

    #leftModal .list-modal-left-register {
        height: 25px;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 5px;
    }

    #leftModal .minilogo img {
        padding: 0;
        width: 18px;
    }

    #leftModal .minilogo span {
        padding-left: 3px;
    }

    #leftModal .minilogo {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 10px;
    }

    #leftModal hr.modal-hr {
        margin-bottom: 10px;
        margin-top: 10px;
        border-color: #f68c06;
    }

    #leftModal .modal-content .bars-left a.local-ua {
        color: orange;
        text-decoration: none;
    }

    #leftModal .modal-content .bars-left a.local-en {
        color: orange;
        text-decoration: none;
    }

    .icon-left-modal {
        color: #f68c06;
        margin-right: 5px;
    }

    #share-txt {
        color: #9d9d9d;
    }

    .modal-social-share {
        padding: 0;
    }

    .modal-social-share a {
        text-decoration: none;
    }

    .modal-social-share a i {
        margin: 0;
    }

    /*End left modal*/
}

@media (min-width: 739px) and (max-width: 1299px) {
    .img-user img {
        padding: 0px;
    }

    .modal-enter {
        padding: 0;
    }

    .modal-regestry{
        padding: 0;
    }

    /*Right modal*/
    .navtab-registraion {
        padding: 0px;
        margin-top: 15px;
        float: right;
    }

    .navtab-registraion button {
        background-color: white;
        border-color: white;
    }

    .navtab-registraion button:hover {
        background-color: white;
        border-color: white;
    }

    .navtab-registraion button.btn.btn-default.modal-enter:focus {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    .navtab-registraion button.btn.btn-default.modal-regestry:focus {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    #rightModal .modal-content {
        width: 290px;
        margin-top: 15px;
        margin-right: 20px;
        border-radius: 15px;
        border-color: #f68c06;
        background-color: #C4C4C4;
        float: right;
    }

    #rightModal .modal-content #modalTab {
        background-color: #C4C4C4;
        border-radius: 15px 15px 0 0;
    }

    #rightModal .modal-content ul#modalTab .btn-modal-enter {
        width: 140px;
        margin-right: 0px;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        color: #f68c06;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-enter {
        border-top-color: #f68c06;
        border-left-color: #f68c06;
        border-right-color: #f68c06;
        color: #f68c06;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-enter-opacity {
        background-color: #C4C4C4;
        opacity: 0.7;
    }

    #rightModal .modal-content ul#modalTab .btn-modal-reg {
        width: 148px;
        margin-right: 0px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: #f68c06;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-reg {
        border-top-color: #f68c06;
        border-left-color: #f68c06;
        border-right-color: #f68c06;
        color: #f68c06;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-reg-opacity {
        background-color: #C4C4C4;
        opacity: 0.7;
    }

    #rightModal .modal-content ul#modalTab .leftBtn-modal-tab {
        margin-left: 50px;
    }

    #rightModal .modal-content .form-group {
        margin-bottom: 5px;
    }

    #rightModal .modal-content .form-group .control-label {
        display: none;
    }

    #rightModal .modal-content .form-group input.form-control {
        border: 1px solid #333;
        width: 258px;
    }

    #rightModal .modal-content .form-group div.col-md-6.col-md-offset-4 {
        margin-left: 0px;
        width: 288px;
        height: 20px;
    }

    #rightModal .modal-content .form-group .checkbox {
        padding: 0;
    }

    #rightModal .modal-content .form-group button.btn.btn-primary.enter-button {
        width: 258px;
        height: 34px;
    }

    #rightModal .modal-content .form-group .member-btn {
        display: none;
    }

    #rightModal .modal-content .form-group a {
        color: #333;
        text-decoration: underline;
        font-size: 12px;
        height: 25px;
    }

    #rightModal .modal-content .tab-content #panel1 p {
        font-size: 12px;
        color: #9d9d9d;
        margin: 0px;
    }

    #rightModal .modal-content .tab-content #panel1 .modal-social-icon {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    #rightModal .modal-content .tab-content #panel1 .modal-social-icon .fa-soc {
        margin: 0;
    }

    #rightModal .modal-content .tab-content #panel2 .form-group .register-button {
        width: 258px;
        height: 34px;
    }

    #rightModal .modal-content .tab-content #panel2 .form-group .checkbox {
        font-size: 12px;
    }

    #rightModal .modal-content .tab-content #panel2 p {
        font-size: 12px;
        margin-top: 30px;
        margin-bottom: 0px;
        color: #9d9d9d;
    }

    #rightModal .modal-content .tab-content #panel2 .modal-social-icon {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    #rightModal .modal-content .tab-content #panel2 .modal-social-icon .fa-soc {
        margin-left: 0px;
    }

    #rightModal .modal-content .tab-content {
        border: 1px solid #f68c06;
        border-radius: 0 0 15px 15px;
        background-color: white;
    }

    /*End right modal*/
    nav.navbar.navbar-default {
        border-top-width: 0px;
        border-right-width: 0px;
        border-left-width: 0px;
        background-color: white;
        padding: 0px;
    }

    .navtab-exit p {
        font-size: 12px;
        margin: 0;
    }

    .navtab-exit + .navtab-exit {
        width: 70px;
    }

    .navtab-exit a {
        text-decoration: none;
        float: right;
    }

    .navtab-exit {
        padding: 0;
    }

    .img-user {
        padding: 0;
        width: 35px;
        height: 35px;
        float: left;
        border: 1px solid #f68c06;
    }

    .img-user-name {
        display: table-cell;
        vertical-align: middle;
        padding-left: 3px;
        padding-right: 1px;
        position: relative;
    }

    .navtab-exit .modal-user-button {
        float: right;
        border: 0;
        color: #f68c06;
    }

    .navtab-exit .modal-exit-button {
        font-size: 9px;
        border: 0;
        color: #f68c06;
    }

    .navtab-exit button:hover {
        background-color: white;
        color: #f68c06;
        text-decoration: underline;
    }

    .navtab-exit .modal-exit-button:focus {
        color: #f68c06;
        background-color: white;
        outline-color: white;
    }

    .navtab-exit .modal-user-button:focus {
        color: #f68c06;
        background-color: white;
        outline-color: white;
    }

    .navtab-registraion ul.nav.nav-tabs {
        float: right;
        margin-top: 16px;
    }

    nav.navbar.navbar-default .center-block img {
        padding-left: 0px;
        padding-right: 0px;
    }

    /*Left modal*/
    button.btn.btn-default.only-bars {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    button.btn.btn-default.only-bars:hover {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    .text-left-modal a {
        color: #333;
        text-decoration: none;
    }

    .text-left-modal a:hover {
        color: #f68c06;
        text-decoration: none;
    }

    .text-left-modal a:focus {
        color: #f68c06;
        text-decoration: none;
    }

    #leftModal .modal-content {
        margin-left: 0px;
        width: 232px;
    }

    #leftModal .modal-content button.btn.btn-default.col-xs-3.list-in-bars {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    #leftModal .modal-content button.btn.btn-default.col-xs-3.list-in-bars:hover {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    #leftModal .modal-content .bars-left {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 10px;
    }

    #leftModal .modal-content .bars-left span {
        color: #f68c06;
    }

    #leftModal .modal-content .bars-left a {
        color: #f68c06;
    }

    #leftModal .list-modal-left {
        height: 25px;
        padding-left: 0px;
        padding-right: 0px;
    }

    #leftModal .list-modal-left-register {
        height: 25px;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 5px;
    }

    #leftModal .minilogo img {
        padding: 0;
        width: 18px;
    }

    #leftModal .minilogo span {
        padding-left: 3px;
    }

    #leftModal .minilogo {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 10px;
    }

    #leftModal hr.modal-hr {
        margin-bottom: 10px;
        margin-top: 10px;
        border-color: #f68c06;
    }

    #leftModal .modal-content .bars-left a.local-ua {
        color: orange;
        text-decoration: none;
    }

    .icon-left-modal {
        color: #f68c06;
        margin-right: 5px;
    }

    #share-txt {
        color: #9d9d9d;
    }

    .modal-social-share {
        padding: 0;
    }

    .modal-social-share a {
        text-decoration: none;
    }

    .modal-social-share a i {
        margin: 0;
    }

    /*End left modal*/
}

@media (min-width: 572px) and (max-width: 768px) {
    .img-user img {
        padding: 0px;
    }

    button.btn.btn-default.only-bars {
        background-color: white;
        border-color: white;
        outline-color: white;
    }
}

@media (min-width: 720px) and (max-width: 1280px) {
    #navregenterbutn button {
        padding: 0;
        font-size: 9px;
    }

    .modal-regestry{
        padding: 0;
    }

    .modal-enter {
        padding: 0;
    }

    .navtab-exit {
        padding: 0;
        float: right;
    }

    .img-user img {
        padding: 0px;
    }

    button.btn.btn-default.only-bars {
        float: left;
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    button.btn.btn-default.only-bars:hover {
        float: left;

    }
}

@media (min-width: 412px) and (max-width: 768px) {
    .modal-user-button {
        float: right;
        border: 0;
        color: #f68c06;
    }

    .modal-exit-button {
        font-size: 9px;
        border: 0;
        color: #f68c06;
        padding: 0px;
    }

    .navtab-exit a {
        text-decoration: none;
        float: right;
    }

    .pensil7px{
        width: 6px;
    }

    .navtab-exit {
        padding: 0;
        float: right;
    }

    .img-user {
        padding: 0;
        width: 30px;
        height: 30px;
        float: left;
        border: 1px solid #f68c06;
    }

    .img-user img {
        padding: 0;
    }

    .img-user-name {
        display: table-cell;
        vertical-align: middle;
        padding-left: 3px;
        padding-right: 1px;
        position: relative;
    }

    .img-user-name p {
        padding: 0px;
        margin: 0;
        font-size: 10px;
    }

    /*Right modal*/
    #navregenterbutn button {
        padding: 0;
        font-size: 9px;
    }

    .only-bars {
        padding: 0;
    }

    .navbar-default {
        font-size: 9px;
        background-color: white;
        padding: 0;
        border: 0;
    }

    .center-block {
        padding: 5px 5px 0 0;
    }

    .row {
        margin: 0;
    }

    .navtab-registraion {
        padding: 0px;
        float: right;
    }

    .navtab-registraion button {
        background-color: white;
        border-color: white;
    }

    .navtab-registraion button:hover {
        background-color: white;
        border-color: white;
    }

    .navtab-registraion button.btn.btn-default.modal-enter:focus {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    .navtab-registraion button.btn.btn-default.modal-regestry:focus {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    .navtab-registraion .modal-enter {
        padding: 0;
        font-size: 11px;
    }

    .navtab-registraion .modal-regestry {
        padding-bottom: 1px;
        padding-top: 1px;
        padding-right: 1px;
        padding-left: 1px;
        font-size: 11px;
    }

    #rightModal .modal-content {
        width: 252px;
        margin-top: 1%;
        margin-right: 1%;
        border-radius: 15px;
        border-color: #f68c06;
        background-color: #C4C4C4;
        float: right;
        font-size: 10px;
    }

    #rightModal .modal-content #modalTab {
        background-color: #C4C4C4;
        border-radius: 15px 15px 0 0;
    }

    #rightModal .modal-content ul#modalTab .btn-modal-enter {
        width: 125px;
        margin-right: 0px;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        color: #f68c06;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-enter {
        border-top-color: #f68c06;
        border-left-color: #f68c06;
        border-right-color: #f68c06;
        color: #f68c06;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-enter-opacity {
        background-color: #C4C4C4;
        opacity: 0.7;
        width: 125px;
        height: 40px;
    }

    #rightModal .modal-content ul#modalTab .btn-modal-reg {
        width: 125px;
        margin-right: 0px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: #f68c06;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-reg {
        border-top-color: #f68c06;
        border-left-color: #f68c06;
        border-right-color: #f68c06;
        color: #f68c06;
        width: 125px;
        height: 40px;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-reg-opacity {
        background-color: #C4C4C4;
        opacity: 0.7;
    }

    #rightModal .modal-content ul#modalTab .leftBtn-modal-tab {
        margin-left: 50px;
    }

    #rightModal .modal-content .form-group {
        margin-bottom: 5px;
    }

    #rightModal .modal-content .form-group .control-label {
        display: none;
    }

    #rightModal .modal-content .form-group input.form-control {
        border: 1px solid #333;
        width: 220px;
    }

    #rightModal .modal-content .form-group div.col-md-6.col-md-offset-4 {
        margin-left: 0px;
        width: 288px;
        height: 20px;
    }

    #rightModal .modal-content .form-group .checkbox {
        padding: 0;
    }

    #rightModal .modal-content .form-group button.btn.btn-primary.enter-button {
        width: 220px;
        height: 29px;
        font-size: 12px;
    }

    #rightModal .modal-content .form-group .member-btn {
        display: none;
    }

    #rightModal .modal-content .form-group a {
        color: #333;
        text-decoration: underline;
        font-size: 9px;
        height: 20px;
    }

    #rightModal .modal-content .tab-content #panel1 p {
        font-size: 12px;
        color: #9d9d9d;
        margin: 0px;
    }

    #rightModal .modal-content .tab-content #panel1 .modal-social-icon {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    #rightModal .modal-content .tab-content #panel1 .modal-social-icon .fa-soc {
        margin: 0;
    }

    #rightModal .modal-content .tab-content #panel2 .form-group .register-button {
        width: 220px;
        height: 29px;
        font-size: 12px;
    }

    #rightModal .modal-content .tab-content #panel2 .form-group .checkbox {
        font-size: 9px;
    }

    #rightModal .modal-content .tab-content #panel2 p {
        font-size: 12px;
        margin-top: 30px;
        margin-bottom: 0px;
        color: #9d9d9d;
    }

    #rightModal .modal-content .tab-content #panel2 .modal-social-icon {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    #rightModal .modal-content .tab-content #panel2 .modal-social-icon .fa-soc {
        margin-left: 0px;
    }

    #rightModal .modal-content .tab-content {
        border: 1px solid #f68c06;
        border-radius: 0 0 15px 15px;
        background-color: white;
    }

    /*Left modal*/
    #leftModal .modal-content {
        width: 232px;
        margin-left: 0px;
        font-size: 10px;
    }

    #leftModal .modal-content .bars-left {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }

    #leftModal .minilogo {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 5px;
    }

    #leftModal .list-modal-left {
        height: 15px;
        padding-left: 0px;
        padding-right: 0px;
    }

    button.btn.btn-default.only-bars {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    button.btn.btn-default.only-bars:hover {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    .text-left-modal a {
        color: #333;
        text-decoration: none;
    }

    .text-left-modal a:hover {
        color: #f68c06;
        text-decoration: none;
    }

    .text-left-modal a:focus {
        color: #f68c06;
        text-decoration: none;
    }

    #leftModal .modal-content button.btn.btn-default.col-xs-3.list-in-bars {
        background-color: white;
        border-color: white;
        outline-color: white;
        margin-left: -14px;
    }

    #leftModal .modal-content button.btn.btn-default.col-xs-3.list-in-bars:hover {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    #leftModal .modal-content .bars-left span {
        color: #f68c06;
    }

    #leftModal .modal-content .bars-left a {
        color: #f68c06;
    }

    #leftModal .list-modal-left-register {
        height: 25px;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 5px;
    }

    #leftModal .minilogo img {
        padding: 0;
        width: 18px;
    }

    #leftModal .minilogo span {
        padding-left: 3px;
    }

    #leftModal hr.modal-hr {
        margin-bottom: 10px;
        margin-top: 10px;
        border-color: #f68c06;
    }

    #leftModal .modal-content .bars-left a.local-ua {
        color: orange;
        text-decoration: none;
    }

    .icon-left-modal {
        color: #f68c06;
        margin-right: 5px;
    }

    #share-txt {
        color: #9d9d9d;
    }

    .modal-social-share {
        padding: 0;
    }

    .modal-social-share a {
        text-decoration: none;
    }

    .modal-social-share a i {
        margin: 0;
    }

    /*End left modal*/
}

@media (min-width: 300px) and (max-width: 480px) {
    .row {
        margin: 0;
    }

    img#entry {
        padding: 0;
    }

    img#registry {
        padding: 0;
    }

    /*Right modal*/
    .navtab-registraion {
        padding: 0px;
        margin-top: 20px;
        float: right;
    }

    .navtab-registraion button {
        background-color: white;
        border-color: white;
    }

    .navtab-registraion button:hover {
        background-color: white;
        border-color: white;
    }

    .navtab-registraion button.btn.btn-default.modal-enter:focus {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    .navtab-registraion button.btn.btn-default.modal-regestry:focus {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    .navtab-registraion .modal-regestry {
        padding: 0;
        font-size: 9px;
    }

    .navtab-registraion .modal-enter {
        padding: 0;
        font-size: 9px;
    }

    #rightModal .modal-content {
        width: 252px;
        margin-top: 1%;
        margin-right: 1%;
        border-radius: 15px;
        border-color: #f68c06;
        background-color: #C4C4C4;
        float: right;
        font-size: 10px;
    }

    #rightModal .modal-content #modalTab {
        background-color: #C4C4C4;
        border-radius: 15px 15px 0 0;
    }

    #rightModal .modal-content ul#modalTab .btn-modal-enter {
        width: 125px;
        margin-right: 0px;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        color: #f68c06;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-enter {
        border-top-color: #f68c06;
        border-left-color: #f68c06;
        border-right-color: #f68c06;
        color: #f68c06;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-enter-opacity {
        background-color: #C4C4C4;
        opacity: 0.7;
        width: 125px;
        height: 40px;
    }

    #rightModal .modal-content ul#modalTab .btn-modal-reg {
        width: 125px;
        margin-right: 0px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: #f68c06;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-reg {
        border-top-color: #f68c06;
        border-left-color: #f68c06;
        border-right-color: #f68c06;
        color: #f68c06;
        width: 125px;
        height: 40px;
    }

    #rightModal .modal-content ul#modalTab .css-btn-modal-reg-opacity {
        background-color: #C4C4C4;
        opacity: 0.7;
    }

    #rightModal .modal-content ul#modalTab .leftBtn-modal-tab {
        margin-left: 50px;
    }

    #rightModal .modal-content .form-group {
        margin-bottom: 5px;
    }

    #rightModal .modal-content .form-group .control-label {
        display: none;
    }

    #rightModal .modal-content .form-group input.form-control {
        border: 1px solid #333;
        width: 220px;
    }

    #rightModal .modal-content .form-group div.col-md-6.col-md-offset-4 {
        margin-left: 0px;
        width: 288px;
        height: 20px;
    }

    #rightModal .modal-content .form-group .checkbox {
        padding: 0;
    }

    #rightModal .modal-content .form-group button.btn.btn-primary.enter-button {
        width: 220px;
        height: 29px;
        font-size: 12px;
    }

    #rightModal .modal-content .form-group .member-btn {
        display: none;
    }

    #rightModal .modal-content .form-group a {
        color: #333;
        text-decoration: underline;
        font-size: 9px;
        height: 20px;
    }

    #rightModal .modal-content .tab-content #panel1 p {
        font-size: 12px;
        color: #9d9d9d;
        margin: 0px;
    }

    #rightModal .modal-content .tab-content #panel1 .modal-social-icon {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    #rightModal .modal-content .tab-content #panel1 .modal-social-icon .fa-soc {
        margin: 0;
    }

    #rightModal .modal-content .tab-content #panel2 .form-group .register-button {
        width: 220px;
        height: 29px;
        font-size: 12px;
    }

    #rightModal .modal-content .tab-content #panel2 .form-group .checkbox {
        font-size: 9px;
    }

    #rightModal .modal-content .tab-content #panel2 p {
        font-size: 12px;
        margin-top: 30px;
        margin-bottom: 0px;
        color: #9d9d9d;
    }

    #rightModal .modal-content .tab-content #panel2 .modal-social-icon {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
    }

    #rightModal .modal-content .tab-content #panel2 .modal-social-icon .fa-soc {
        margin-left: 0px;
    }

    #rightModal .modal-content .tab-content {
        border: 1px solid #f68c06;
        border-radius: 0 0 15px 15px;
        background-color: white;
    }

    /*End right modal*/
    nav.navbar.navbar-default {
        border-top-width: 0px;
        border-right-width: 0px;
        border-left-width: 0px;
        background-color: white;
        padding: 0px;
    }

    .navbar-default .bars-left-modal {
        margin-left: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .navbar-default .center-block {
        margin-right: 0px;
        margin-left: 0px;
        margin-top: 0px;
    }

    .navtab-exit {
        float: right;
        padding: 0px;
    }

    .navtab-exit p {
        font-size: 9px;
        margin: 0;
        padding-top: 10px;
        width: 40px;
    }

    .navtab-exit .modal-exit-button {
        padding: 0px;
    }

    .col-xs-4.navtab-exit div.row.pull-left {
        float: right;
        margin: 0px;
    }

    .col-xs-4.navtab-exit div.row.pull-left a.pensil7px{
        width: 8px;
        padding-left: 2px;
    }

    .navtab-exit + .navtab-exit {
        float: right;
    }

    .navtab-exit a {
        text-decoration: none;
        float: right;
    }

    .img-user {
        width: 22px;
        height: 22px;
        float: left;
        border: 1px solid #f68c06;
    }

    .img-user img {
        padding: 0px;
    }

    .img-user-name {
        float: right;
    }

    .img-user-name p {
        padding: 0px;
    }

    .navtab-exit .modal-user-button {
        font-size: 9px;
        border: 0;
        color: #f68c06;
        float: right;
    }

    .navtab-exit .modal-exit-button {
        font-size: 9px;
        margin-left: 0px;
        border: 0;
        color: #f68c06;
    }

    .navtab-exit button:hover {
        background-color: white;
        color: #f68c06;
        text-decoration: underline;
    }

    .navtab-exit .modal-exit-button:focus {
        color: #f68c06;
        background-color: white;
        outline-color: white;
    }

    .navtab-exit .modal-user-button:focus {
        color: #f68c06;
        background-color: white;
        outline-color: white;
    }

    .navtab-registraion ul.nav.nav-tabs {
        float: right;
        margin-top: 16px;
    }

    nav.navbar.navbar-default .center-block img {
        padding-left: 0px;
        padding-right: 0px;
    }

    /*Left modal*/
    #leftModal .modal-content {
        width: 232px;
        margin-left: 0px;
        font-size: 10px;
    }

    #leftModal .modal-content .bars-left {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
    }

    #leftModal .minilogo {
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 5px;
    }

    #leftModal .list-modal-left {
        height: 15px;
        padding-left: 0px;
        padding-right: 0px;
    }

    button.btn.btn-default.only-bars {
        padding: 0;
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    button.btn.btn-default.only-bars:hover {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    .text-left-modal a {
        color: #333;
        text-decoration: none;
    }

    .text-left-modal a:hover {
        color: #f68c06;
        text-decoration: none;
    }

    .text-left-modal a:focus {
        color: #f68c06;
        text-decoration: none;
    }

    #leftModal .modal-content button.btn.btn-default.col-xs-3.list-in-bars {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    #leftModal .modal-content button.btn.btn-default.col-xs-3.list-in-bars:hover {
        background-color: white;
        border-color: white;
        outline-color: white;
    }

    #leftModal .modal-content .bars-left span {
        color: #f68c06;
    }

    #leftModal .modal-content .bars-left a {
        color: #f68c06;
    }

    #leftModal .list-modal-left-register {
        height: 25px;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 5px;
    }

    #leftModal .minilogo img {
        padding: 0;
        width: 18px;
    }

    #leftModal .minilogo span {
        padding-left: 3px;
    }

    #leftModal hr.modal-hr {
        margin-bottom: 10px;
        margin-top: 10px;
        border-color: #f68c06;
    }

    #leftModal .modal-content .bars-left a.local-ua {
        color: orange;
        text-decoration: none;
    }

    .icon-left-modal {
        color: #f68c06;
        margin-right: 5px;
    }

    #share-txt {
        color: #9d9d9d;
    }

    .modal-social-share {
        padding: 0;
    }

    .modal-social-share a {
        text-decoration: none;
    }

    .modal-social-share a i {
        margin: 0;
    }

    /*End left modal*/
}
