


	.col-md-10 {
		width: 83.33333333%;
		margin-left: 164px;
		margin-top: -225px;
	}


body
{
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	line-height: 20px;
}
.hidedPanel
{
    display:none;
}
.shownPanel
{
    display:block;
}
#main
{
	display: inline;
	min-height: 350px;
}
#phName
{
	border-radius: 7px;
	clear: both;
	font-size: 14pt;
	height: 44px;
	margin: 1px;
	padding: 1px 1px 1px 1px;
    font-style: italic;
}
#phName:hover
{
	box-shadow: 0 0 2px 2px rgba(221, 221, 221, 1);
}
.phNameLocation
{
	margin-bottom: 5px;
}
#logoImg
{
	font-family: "Impact", "Tahoma", Arial, "Geneva", sans-serif;
	font-size: 24px;
}
#logo a
{
	color: 	#696969;
	text-decoration: none;
	text-transform: uppercase;
}

#text1
{
	font-family: 'Roboto', sans-serif;
	font-size: 18px;
	color: 	#696969;
}
.textalign,#phName,#center,#content_tests,#tip,#buttons_table,#phName1,#p,#start_button
{
	text-align: center;
}
strong
{
	font: helvetica;
	font-size: 17pt;
	text-align: justify;
}
#content{
	background-color: #ddd;
	border-radius: 5px;
}
span.left
{
	margin-left: -0.25em;
}
span.right
{
	margin-right: -0.25em;
}
.centr
{
	margin: 0 auto;
    width: 300px;
}
.buttonLocation
{
	margin: 0 auto;
}
.button
{
    width: 200px;
    height: 50px;
    border-radius: 5px;
}
.button:hover
{
	border: 1px solid #696969;
}
.button:active
{
	border: 1px solid #696969;
}
.buttonTest
{
    background: #696969;
    border-radius: 5px;
	color: white;
	font-weight: 700;
	width: 60px;
	height: 44px;
    text-decoration: none;
    transition: 0.2s;
}
.buttonTest:hover
{
    background: #696969;
}
.buttonTest:active
{
    background: #696969;
    box-shadow: 0 3px #696969 inset;
}
.buttonTest:disabled
{
	background: #696969;
}
#footer
{
	clear: both;
	height: 10px;
}
#menu_footer
{
	color: #7A2200;
	font-size: 11px;

}
#down_footer
{
	color: black;
	font-size: 12px;
	margin-bottom:10px;
}
#header
{
	margin-left: 90px;
	font-weight: bold;
}
#start_test
{
	font-size: 15px;
}
#content_tests
{
	font-size: 18px;
	color: #36648B;
	min-height: 80px;
}
#tests_title_tr
{
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
}
#tip
{
	padding: 20px 0;
}
#buttons_table
{
	margin: 20px 0 0 30px;
}
td
{
    width: 80px;
}
.error
{
	color:red;
}
.errorField
{
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: red;
	background-image: none;
	border: 1px solid #cccccc;
	border-radius: 4px;
	border-color:red;
	box-shadow: inset 0 1px 1px red;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

img
{
	box-sizing: border-box;
}

#logoImg:hover
{
    opacity: 0.5;
}
input[type=radio]
{
    display: none;
}
.border
{
	border: 2px double black;
}
fieldset
{
	padding: 0 0 20px 0;
    border: 1px solid #808080;
    border-radius:5px;
}
.fieldset1
{
	padding: 0 0 20px 0;
    border: 2px solid red;
    border-radius:5px;
}
legend
{
	display: block;
	font-size: 21px;
	line-height: inherit;
	width: auto;
}
.legend
{
	display: block;
	font-size: 21px;
	line-height: inherit;
	width: auto;
	color: red;
}
#start_button
{
	margin-bottom: 20px;
}
@font-face
{
    font-family: 'Roboto';
    src: url('fonts/Roboto-webfont.eot?') format('embedded-opentype');
}
@font-face
{
    font-family: 'Roboto';
    src: url('fonts/Roboto-Medium-webfont.eot?') format('eot'),
         url('fonts/Roboto-Medium-webfont.svg#svgFontName') format('svg');
}
p
{
	font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
}
.b
{
	height: 86px;
	width: 106px;
	margin: 0 10px;
}
.errorField
{
	border-color: red;
	color:red;
}
#phName1
{
	color: red;
    border: 2px solid red;
}

.b p
{
	height: 40px;
	font-weight: 600;
	font-size: 12px;
}
div .tit_b
{
	height: 40px;
}

textarea
{
    resize: none;
}
span.heading{
	color: #797979;
	font-size: 16px;
}
.myResume{
	text-align: left;
}

.panel-orange {
word-wrap: break-word;
}
.panel-orange .list-group{
	word-wrap: normal;
}
.panel-orange {
    background-color: #ffffff;
    border:3px solid #f26522;
    font-size: large;
    color: #f26522;
}
.text-info{
	color:#d96123;
}
a.link{
	text-decoration: none;
}
.list:hover
{
	text-decoration:none;
	border:2px solid #d96123;
}
.navbar-right{
margin-top: 20px;
}
.formTitle{
	text-indent: 1.5em;
}
.errors{
	border-color: red;
}
.has-error span[role='combobox']{
	border-color:#a94442;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
li[role='treeitem'].select2-results__option{
	border-color:#a94442;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	color: black !important;
}
.crResVac{
	padding-bottom: 10px;
	float:right;
}
#logoImg{
    min-width: 300px;
    padding: 15px 15px;
    width: 100px;
    position: relative;
    z-index: 2;
    margin-top: -80px;
	padding-bottom: 0px;
	padding-top: 20px;
}
#dropTitle{
	display:inline-block;
}
#selectIndustry{
	width:380px;
}

#selectCity{
	width:250px;
}
.btn_cr_ResVac{
	margin-right: 15px;
	margin-bottom: 20px;
	text-decoration: none;
}

.btn_cr{
	float:right;
	margin-top: 0;
}


.list-group-item
{
    background-color: #f5f5f5;
    word-break: break-all;
    border-left: none;
    border-right: none;
    width: 100%;
}

#vrBlock

{
    border: 1px #dddddd;
    border-style: solid hidden solid hidden;
    border-radius: 0px;
    /*width: 100%;*/
	margin-left: 15px;
	margin-right: 15px;
}


#vimg
{
    width: 136px;
    height: 136px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 5px;
	margin-bottom:0;
	border-radius: 10px;
	border: 3px solid #f26522;
}

#datAnnoyingSizes
{
   /* display: table-cell;*/
}

#yellowCircle
{
    color: #f48952;
    font-size: 27px;
}


.logos
{
	  vertical-align: top;
      display: table-cell;
      width: 200px;
}

.staticLinks
{
    height: 40px;
    margin-right: 5%;
    font-family: "Roboto",Helvetica,Arial,sans-serif;

}

.staticLinks > .btn:hover,
.staticLinks > .btn:focus
{
    border: 1px solid #f48952;
    border-radius: 10px;
    color: #f48952 !important;
    font-weight:bold;
}
.navbar-default
{
    position: relative;
    z-index: 1;
}
.form-group .register-button{
	background-color: #f68c06;
	border-color: #f68c06;
}

.form-group .register-button:hover{
	background-color: #f26522;
	border-color: #f26522;
}
button.btn.btn-primary.register-button:focus{
	background-color: #f26522;
	border-color: #f26522;
}
.form-group .enter-button{
	background-color: #f68c06;
	border-color: #f68c06;
}
.form-group .enter-button:hover{
	background-color: #f26522;
	border-color: #f26522;
}
button.btn.btn-primary.enter-button:focus{
	background-color: #f26522;
	border-color: #f26522;
}
.form-group a.login-text{
	color: #f68c06;
	text-decoration: none;
	padding-left: 0px;
}

.form-group a.login-text:hover{
	color: #f26522;
	font-weight: bold;
	text-decoration: none;
}
#vimg img{
	border-radius: 7px;
	/*width: 100%;*/
	height: 100%;
}

/* -Ratings- */
.ratings{
	font-family: 'Segoe UI';
	color: #6e6e6e;
	font-size: 16px;
	font-weight: 400;
}

#like {
	margin-bottom: 7px;
	padding:6px 0px 6px 6px;
}

#dislike {
	margin-bottom: 4px;
	padding:6px 0px 6px 6px;
}

.likeDislike {
	margin-bottom: 7px;
	cursor: pointer;
}

.findLike{
	margin-right: 10px;
	color: #CD0100;
	font-size: 18px;
	font-family: SegoeUIRegular;
}

.findDislike{
	color: #818181;
	font-size: 18px;
	font-family: SegoeUIRegular;
}

#rightModal .modal-social-icon span.logoINTITA{
	display: inline-block;
	background-image: url('../image/logoINTITA.jpg');
	background-size: contain;
	border-radius: 7px;
	height: 36px;
	width: 36px;
	top: 2px;
	position: relative;
}

@media(min-width: 0px) and (max-width: 738px) {
	#rightModal .modal-social-icon span.logoINTITA{
		border-radius: 6px;
		height: 26px;
		width: 26px;
		top: 3px;
	}
}

#rightModal .modal-social-icon span.logoINTITA:hover{
	background-image: url('../image/orangeLogoINTITA.jpg');
}


.footer ul, .footer  li{
	list-style-type:none;
	padding-bottom: 10px;
}

.footer a{
	color: #9d9d9d;
}
.container-footer {
	background-color: #3f3e3e;
	padding: 20px 0;
	margin-top: 30px;
	color: #9d9d9d;
	font-size: 18px;
}
.container-footer i{
	color: #d17c11;
	margin-right: 15px;
}

.inner-footer{
	float: left;
}
.inner-footer img{
	margin-right: 10px;
}

.inner-footer hr{
	margin:0px 0px 20px 0px;
}

.down-footer{

	background-color: #8f8f8f;
	font-size: 14px;
	padding: 20px;
}

.li_header{
	margin: 30px 0px 20px 0px;
	padding: 0px;
	font-family: ACLine;
	font-size:24px;
	color: white;
	font-weight: bold;
}

.li_header img{
	margin-right: 10px;
}

.footer a:hover{
	color: #d17c11;
	text-decoration: none;
}

.footer :link{
	text-decoration: none;
}
html,body{
  margin:0;
  padding:0;
  height: 100%;
}
.down-footer img{
  margin-left: 10px;
}
.footer ul, .footer  li{
  list-style-type:none;
  padding-bottom: 10px;
}

.footer a{
  color: #9d9d9d;
}

.container-footer {
  background-color: #3f3e3e;
  padding: 20px 0;
  margin-top: 30px;
  color: #9d9d9d;
  font-size: 18px;
}
.container-footer i{
  color: #d17c11;
  margin-right: 15px;
}

.inner-footer{
  float: left;
}
.inner-footer img{
  margin-right: 10px;
}

.inner-footer hr{
  margin:0px 0px 20px 0px;
}

.down-footer{
  background-color: #8f8f8f;
  font-size: 14px;
  padding: 20px;
}

.li_header{
  margin: 30px 0px 20px 0px;
  padding: 0px;
  font-family: ACLine;
  font-size:24px;
  color: white;
  font-weight: bold;
}

.li_header img{
  margin-right: 10px;
}

.footer a:hover{
  color: #d17c11;
  text-decoration: none;
}

.footer :link{
  text-decoration: none;
}

.fa-soc {
  color: #3f3e3e;
  margin-left: 10px;
}
.fa-soc:hover {
  color:#d17c11;
}

.wrapper-page{
  min-height: calc(100vh - 368px);
}
.container.container-main{
  min-height: calc(100vh - 368px);
}
